<template>
    <!-- Модальне вікно для вибору параметрів -->

    <modal :stylebody="'var(--vz-body-bg)'">
        <template v-slot:title>{{ $t('Параметри') }}</template>

        <template v-slot:head-bottom>
            <!-- Кнопка закриття модального вікна -->
            <button 
                type="button" 
                class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"
                @click="$emit('close')"
            >
                <i class="ri-close-fill align-bottom"></i>
            </button>
        </template>

        <template v-slot:body>

            <!-- Відображення блоків -->
            <b-row>
                <template v-for="item in blocks" :key="item">
                    <b-col lg="4" >
                        <div class="card">
                            
                            <!-- Відображення кнопки для видалення блока, якщо блок активний -->
                            <div class="disabledBlock" v-if="item.status">
                                <button type="button" class="btn btn-danger mt-3 fs-14 fw-semibold" @click="removeItem(item)">{{ $t('Прибрати') }}</button>
                            </div>

                            <!-- Загальна інформація про блок -->
                            <div class="card-body copy" @click="selectBox(item)">
                                <div class="text-center">
                                    <!-- Іконка -->
                                    <div class="mb-2">
                                        <i :class="`${item.icon} align-bottom text-warning display-5`"></i>
                                    </div>
                                    <!-- Назва параметру -->
                                    <h6 class="fs-15 folder-name fw-semibold">{{ item.title }}</h6> 
                                    <!-- Опис параметру -->
                                    <span class="fs-13 text-muted">{{ item.desc }}</span>
                                </div>
                            </div>

                        </div>
                    </b-col>
                </template>
            </b-row>
        </template>

        <template v-slot:footer-bottom>
            <!-- Кнопка для збереження налаштувань -->
            <button type="button" class="btn btn-success mt-3" @click="save">{{ $t('save') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small' // компонент вікна

export default {
    props: ['activeBlocks'],
    components: {
        modal,
    },
    data(){
        return{
            form: '',
            blocks: [
                {
                    "title": "Загальний",
                    "type": "main",
                    "desc": "Цей блок включає в себе інформацію про пристрій та застосунок для тестування",
                    "status": false,
                    "icon": 'ri-dashboard-fill',
                    "color": ''
                },
                {
                    "title": "Пристрої",
                    "type": "devices",
                    "desc": "Цей блок включає в себе можливість додати додаткове обладнання для тестування",
                    "status": false,
                    "icon": 'ri-device-line',
                    "color": ''
                },
                {
                    "title": "Алгоритм",
                    "type": "algorithm",
                    "desc": "Цей блок включає в себе можливість додати покроковість виконання дій щодо виконання завдання",
                    "status": false,
                    "icon": 'ri-edit-box-line',
                    "color": ''
                },
                {
                    "title": "Опис",
                    "type": "desc",
                    "desc": "Цей блок включає в себе можливість додати опис до картки завдання",
                    "status": false,
                    "icon": 'ri-text',
                    "color": ''
                },
            ]
        }
    },
    created(){
        this.getdata()
        console.log("this", this);
    },
    methods: {
        getdata(){
            // Отримання налаштувань з активних блоків
            if(this.activeBlocks.length > 0){
                this.blocks = this.activeBlocks
            }
        },
        selectBox(e){
            // Вибір блоку
            e.status = true
            console.log('e1');
            // this.actBlocks.push(e)
        },
        removeItem(e){
            // Приховання блоку
            console.log('e', e.status);
            e.status = false
            console.log('e', e.status);
        },
        save(){
            // Збереження налаштувань
            // console.log('this>', this);
            this.$emit("saveBlock", this.blocks)
            this.$emit("close")
        }
    },
}
</script>

<style> 
.disabledBlock {
    width: 100%;
    height: 100%;
    background: hsla(0,0%,100%,.9);
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}
</style>